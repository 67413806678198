.unit-card-v2 {
	&:hover {
		.go-to-unit {
			opacity: 1;
		}
	}

	.h5 {
		a {
			font-size: var(--font-size-main);
			color: var(--color-black);

			&:hover {
				color: var(--link-font-color);
			}
		}
	}

	.unit-infomation {

		.fa,
		.icon {
			margin-left: 5px;
		}

		.d-table-cell {
			&:first-child {
				font-size: var(--font-size-md);


				.fa {
					color: var(--color-grey-dark);
					margin-left: 0;
				}
			}
		}

		.icon-fa-rv,
		.icon-fa-tent {
			position: relative;
			top: 3px;
		}
	}

	.comodities {
		padding: 10px 0;
		min-height: 109px;
	}

	.content {
		.h5 {
			min-height: 42px;
		}
	}

	.price {
		position: absolute;
		background-color: #305d4c;
		background-color: var(--color-primary);
		text-align: center;
		top: 20px;
		right: -10px;
		padding: 0 10px;
		z-index: 2;
		color: var(--color-secondary);

		.h3 {
			font-size: 18px;
			color: var(--color-secondary);
			line-height: 28px;
		}
	}

	.content {
		padding: 10px;
		display: flex;
		flex-direction: column;
		min-height: 150px;
		justify-content: space-between;
		border: 1px solid var(--color-grey-normal);
		background-color: var(--white);

		.comodities {
			a {

				color: var(--font-color-main);

				&:hover {
					text-decoration: none;
				}
			}
		}



		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			color: var(--color-black);
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
			}

			.btn {
				font-size: var(--font-size-md);
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}
	}

	.fav-icon {
		position: absolute;
		left: 10px;
		top: 10px;
		color: #fff;
		cursor: pointer;
		z-index: 2;
	}

	.place {
		font-size: var(--font-size-md);

		.fa {
			color: var(--color-grey-dark);
		}
	}

	.properties-row {
		display: flex;

		.unit-properties {
			font-size: .875rem;
			font-size: var(--font-size-md);

		}
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;

		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;

		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.rules {
			position: absolute;
			bottom: 10px;
			left: 10px;
			background-color: white;
			padding: 0;
			border-radius: 5px;
			color: var(--font-color-main);

			.fa {
				padding-top: 8px;
				padding-bottom: 8px;
				padding-right: 5px;
			}

			.fa:first-child {
				padding-left: 5px;
			}

			.fa:last-child {
				padding-right: 5px;
			}
		}


	}

	.go-to-unit {
		height: 30px;
		display: inline-block;
		width: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 50%;
		background-color: #305d4c;
		background-color: var(--color-primary);
		-webkit-transition: all .5s;
		transition: all .5s;
		opacity: 0;

		.fa {
			color: var(--color-white);
			font-size: 20px;
			line-height: 30px;
			padding-right: 0;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}
}