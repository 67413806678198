.search-page-layout-v3 {
	background-color: var(--color-grey-light);
	margin-top: var(--page-margin-top);

	.mobile-bar {
		background-color: var(--color-white);
		position: fixed;
		bottom: 0;
		padding: 12px;
		width: 100%;
		z-index: 10;
		left: 0;


	}

	.sort-row {
		@media (max-width:767px) {
			position: fixed;
			bottom: 12px;
			z-index: 11;
			right: 15px;

			.sort-by {
				strong {
					width: 40px !important;
					height: 41px !important;
					border-radius: 0 !important;
					box-shadow: inherit !important;
					border: none !important;
					border-color: var(--btn-primary-border-color);
					color: var(--btn-primary-font-color) !important;
					background-color: var(--btn-primary-bg-color);
					line-height: 33px !important;

					.fa-sort-amount-down {
						font-size: 18px !important;
						/*color: var(--color-white) !important;*/

					}

					&:hover {
						background-color: var(--btn-primary-bg-color-hover);

						i {
							color: var(--btn-primary-font-color-hover);
						}
					}
				}



			}
		}

	}


	.search-tocken {
		display: flex;
		font-size: 14px;
		align-items: center;
		flex-wrap: wrap;

		.result-count {
			padding: 2px 6px
		}
	}

	.header-action-bl {
		display: flex;
		justify-content: space-between;
	}

	.results {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 30px;

		@media (min-width:1200px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		}

		/*	@media (max-width:992px) {
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
		} */

		@media (max-width:992px) {
			grid-template-columns: 1fr;
		}

		.unit-infomation {
			/*.fa-restroom {
				height: 16px;
				width: 16px;
				margin: 0 22px;

				&:before {
					content: "";
					background-image: url(RESOURCE/img/icon-restroom.svg);
					background-size: contain;
					background-repeat: no-repeat;
					height: 21px;
					position: absolute;
					width: 21px;
				}
			}*/
		}

	}

	.more-btn-panel {
		margin-top: 30px;
	}

	.container,
	.container-xl {
		@media (min-width:1200px) {
			max-width: 1200px;
		}

		@media (min-width:1500px) {
			max-width: 1370px;
		}
	}

}

.my-search-token-v2 {
	margin: 2px;
	padding: 2px 6px;
	display: inline-block;
	border: 1px solid var(--color-grey-normal);
	border-radius: 4px;
	background-color: var(--color-grey-light);
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	margin-right: 8px;

	.close {
		transition: all 0.5s ease;
		position: absolute;
		height: 13px;
		width: 13px;
		right: -7px;
		top: -7px;
		font-size: 14px;
		text-align: center;
		background-color: var(--color-primary);
		color: #fff;
		border-radius: 50%;
		font-weight: 400;
		line-height: 13px;
		opacity: 0;
	}

	&:hover {
		border: 1px solid var(--color-primary);
		background-color: var(--color-white);

		.close {
			opacity: 1;
		}
	}
}