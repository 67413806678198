.unit-information-box-v1 {
	padding: 20px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.info-icons {
		>span {
			display: flex;
			align-items: center;
			/*height: 29px;*/

			span {
				position: relative;
				top: -1px;
			}
		}
	}

	.location,
	.ul-nopints li {
		position: relative;
		padding-left: 28px;

		i {
			position: absolute;
			left: 4px;
			top: 4px;
		}

		.fa-stack {
			position: absolute;
			left: 4px;
			top: -6px;
		}
	}



	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}





	.stars {
		color: var(--color-stars);
	}

}