.search-map-pane-v1 {
	-webkit-box-flex: 1;
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;
	min-width: 0;
	min-height: 0;
	flex-direction: inherit;

	.header {
		display: none;
	}

	.leaflet-popup-content {
		font-weight: bold;
		font-size: 14px;
	}

	.unit-icons {
		margin-top: 10px;
		display: inline-flex;

		i {
			margin-right: 7px;
			font-size: var(--font-size-main);
			color: #000;
		}
	}

	.flex {
		-webkit-box-flex: 1;
		-webkit-flex: 1 1 0;
		-ms-flex: 1 1;
		flex: 1 1 0;
		min-width: 0;
		min-height: 0;

		@media (min-width: 993px) {
			height: calc(100vh -178px);
		}


		@media (max-width:992px) {
			height: calc(100vh -178px);
		}


	}

	.unit-title {
		font-size: var(--font-size-main);
		padding-top: 10px;
		font-weight: 600;
	}

	.unit-place {
		padding-top: 10px;
	}

	.preview-img {
		max-width: 320px;

		img {
			-o-object-fit: cover;
			object-fit: cover;
			-o-object-position: center center;
			object-position: center center;
			height: 100%;
			width: 100%;
		}
	}

	.unit-properties {

		margin-top: 10px;

		li {
			padding-right: 15px;
			display: inline-block;
			line-height: 20px;

			.fa {
				width: 20px;

				.fa-stack {
					margin-left: -10px;
					line-height: 1.4em;
				}
			}
		}
	}


}