.unit-booking-v1 {

	.widget-header {
		color: var(--color-secondary);
		background-color: var(--color-primary);
		font-size: 32px;
	}

	.asd__mobile-close-icon {
		display: block;
		position: absolute;
		right: 10px;
		top: 4px;
		border: none;

		@media (min-width:993px) {
			display: none;
		}
	}

	.search-bl {
		position: relative;

		.fa {
			position: absolute;
			right: 0;
			top: 15px;
			color: var(--color-black);
			pointer-events: none;
			font-size: 24px;
		}


	}

	.alert-info {
		color: var(--color-black);
	}

	.form-control {
		border: none;
		border-bottom: 1px solid #e6e6e6;
		text-align: left;
		margin-bottom: 15px;
		padding: 0.375rem 0.75rem;
		background-color: var(--color-white);
		font-size: 1.2575rem;
		cursor: pointer;
		color: var(--color-black);

		&::-webkit-input-placeholder {
			/* Edge */
			color: black;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: black;
		}

		&::placeholder {
			color: black;
		}
	}

	.table {
		td {
			border-top: 2px solid var(--color-white);
		}

		tbody {
			border-top: 2px solid var(--color-white);
		}
	}

	.optionals {
		margin-top: 20px;
	}

	.optionals-title {
		font-size: var(--h4-font-size);
		font-weight: var(--h4-font-weight);
		padding-left: 10px;
	}

	.consumptioncosts-title {
		font-size: var(--h5-font-size);
		font-weight: var(--h5-font-weight);
		padding-left: 10px;
	}

	.button-row {
		text-align: right;
		margin-top: 20px;

	}

	.subrow {
		margin-top: 20px;
	}

	.btn-option {
		margin-top: 10px;
	}
}