.unit-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.unit-booking-v1 {
		.gb-td {
			.form-control {
				max-width: 120px;
				padding: 10px;
			}
		}
	}

	.location {

		.fa {
			margin-right: 8px;
			color: var(--color-black);
		}
	}

	.voucher {
		background-color: #e6e6e6;
		padding: 20px;

		.voucher-info {
			margin: 20px 0px;
		}

		.form-control {
			padding: 0.375rem 0.75rem;
		}
	}

	.facts {

		li {

			margin-bottom: 5px;
			display: flex;
			align-items: center;

			.fa {
				&.fa-bolt {
					text-align: center;
					width: 18px;
					font-size: 18px;

					@media (max-width:767px) {
						margin-right: 11px;
					}
				}

				width: 30px;
				color: var(--color-black) !important;

				&.fa-spa {
					content: "\f5bb";
				}

				&.fa-key {
					content: "\f084";
				}

				@media (max-width:767px) {
					width: 22px;
					margin-right: 7px;
				}
			}


			.icon {
				margin-right: 10px;
				color: var(--color-black);
			}

			.icon-self-checkin {
				position: relative;
				top: 3px;

				@media (min-width:992px) {
					top: 1px;
				}
			}

			.fa-key {
				@media (max-width:767px) {
					margin-right: 1px;
				}
			}

			.fa-stack {
				margin-left: -10px;
				position: relative;
				left: 5px;

				@media (max-width:767px) {
					left: 11px;
					margin-right: -4px;
				}
			}

		}
	}



	#accordion {
		.widget {
			.accord-head {
				>a {
					position: relative;
					text-decoration: none;
					display: block;



					&[aria-expanded="true"] {
						&:after {
							transform: rotate(180deg);
						}


					}


					&:after {
						content: "\f107";
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						position: absolute;
						right: 16px;
						top: 21px;
						font-size: 25px;
						transform: rotate(0deg);
						line-height: 1;
						transition: all .2s ease-in-out;
						color: var(--color-black);

						@media (max-width:992px) {
							top: 16px;
						}
					}

				}
			}

		}
	}

}