.booking-v1 {
	background-color: var(--booking-view-background-color);

	.booking-view {
		margin-top: var(--page-margin-top);
		background-color: var(--booking-view-background-color);

		padding-top: var(--page-margin-top);
		padding-top: 10px;

	}

	.required::after {
		content: "*";
		vertical-align: super;
		font-size: var(--font-size-sm);
	}

	.label {
		display: inline-block;
	}

	.parking-note {
		margin-bottom: 10px;
		font-size: 16px;
	}

	.cart-details {
		padding: 14px;
		background-color: var(--color-white);

		.unit-details {
			margin: 20px 0;
			color: var(--color-grey-dark);

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--color-primary);
				}
			}

			li.address {
				&:before {
					content: "\f3c5";
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: 500;
		}

	}


	.checks {

		.checkbox {
			input {
				position: absolute;
			}

			label {
				vertical-align: inherit;
				display: inline-block;
				padding-left: 26px;
				position: relative;

				&:after {
					top: 5px;
					left: 20px;
				}

				&:before {
					position: absolute;
					left: 20px;
					margin-left: -20px;
					top: 5px;
				}
			}
		}

		a {
			color: var(--font-color-main) !important;
		}
	}



	.flow-btn {
		margin-left: 20px;
	}


}

.paymentmethods {
	display: flex;
	margin-bottom: 30px;

	i {
		color: var(--footer-font-color);
		font-size: 34px;
		display: flex;
		align-items: center;
		margin-right: 8px;

		img {
			width: 70px;
		}
	}
}



.amount-options-list {
	margin: 20px 0;

	.amount-option-btn {
		.amount-or {
			text-align: center;
			margin: 15px 0;
			width: 100%;
			max-width: 305px;
		}

		.btn-default {
			width: 100%;
			max-width: 305px;
		}
	}

}