.agb-v1 {
	margin-top: var(--page-margin-top);


	.inner-banner {
		background-image: url(RESOURCE/img/banner-1.jpg);
	}

	@media (max-width:767px) {
		word-break: break-all;
	}
}